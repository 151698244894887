import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function FortuneReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>About Fortune Group:</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Founded in 2003, Fortune Group is a leading real estate developer renowned for its premium
projects in Hyderabad, India. With a strong portfolio of completed residential and commercial
properties, we have consistently delivered exceptional quality and timely execution.</p>

            </p>

            {isShowMore && (
                 <p className="mb-0">
                <p className="AboutExpoPara"> Our
commitment to innovation is evident in projects like The Fortune Monarch and our upcoming
multiplex.
</p>
<p className="AboutExpoPara"> Driven by passion and a customer-centric approach, Fortune Group has established a strong
foothold in the Hyderabad real estate market. Our focus on meticulous planning, premium
construction, and unparalleled amenities has resulted in a track record of satisfied customers
and industry recognition. As we expand our presence to other major Indian cities and
international markets, we remain steadfast in our commitment to delivering world-class real
estate developments.
</p>
<h4 className='m-0'>About Sensation:</h4>
<p className="AboutExpoPara"> Sensation Group founded by Dr.Bhavishya Gupta (MS in Real Estate, NYU, USA and Doctorate
in Real Estate Business Management, Sorbonne University, Paris) with development contracts
of over 25 Mn sq ft signed & in progress in the spheres of Family Entertainment Centres with
Multiplexes | Digital Detox & Blackhole Resorts | World Class Plotted Developments | Budget
Hotels | Serviced Apartments and State of Art Serviced Hostels for Students, Working Class and
Co-living | Blue Collared Housing, across Pan India as the pioneer of adopting Blue Ocean
Strategy in the business of Real Estate. 
</p>

<p className="AboutExpoPara"> The Operations are run by the Founder and his
valuable partners who hail from high level business backgrounds supported by a team of
professionals having grass root level approach in Real Estate, having full fledged offices in
Dubai, Mumbai, Bengaluru, Chennai, Hyderabad, Goa & Vishakapatnam.
</p>
            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default FortuneReadMore;