import React, { useState } from "react"
import "./assets/styles/_index.scss"
import FortuneAboutSidebar from "./FortuneAboutSidebar"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"

import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrlStand ="https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")


const videoUrl = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function FortuneAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    centerMode: true,
                    centerPadding: "0px",
                    slidesToShow: 1,
                },
            },

        ],

    }

    return (
        <>

            <div className="ProjectAboutPageSectionFortune">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/fortune-sensation-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>

                <div className="d-none d-md-block">
                    <FortuneAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />

                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/fortune-sensation-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/fortune-sensation-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Fortune Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection" style={{ overflowY: "auto", maxHeight: "270px" }}>
                                            <h4 className='m-0'>About Fortune Group:</h4>
                                            <p className="mb-0">
                                            <p className="AboutExpoPara">Founded in 2003, Fortune Group is a leading real estate developer renowned for its premium
projects in Hyderabad, India. With a strong portfolio of completed residential and commercial
properties, we have consistently delivered exceptional quality and timely execution.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

<p className="AboutExpoPara"> Our
commitment to innovation is evident in projects like The Fortune Monarch and our upcoming
multiplex.</p>
<p className="AboutExpoPara">  Driven by passion and a customer-centric approach, Fortune Group has established a strong
foothold in the Hyderabad real estate market. Our focus on meticulous planning, premium
construction, and unparalleled amenities has resulted in a track record of satisfied customers
and industry recognition. As we expand our presence to other major Indian cities and
international markets, we remain steadfast in our commitment to delivering world-class real
estate developments.</p>
<h4 className='m-0'>About Sensation:</h4>
<p className="AboutExpoPara"> Sensation Group founded by Dr.Bhavishya Gupta (MS in Real Estate, NYU, USA and Doctorate
in Real Estate Business Management, Sorbonne University, Paris) with development contracts
of over 25 Mn sq ft signed & in progress in the spheres of Family Entertainment Centres with
Multiplexes | Digital Detox & Blackhole Resorts | World Class Plotted Developments | Budget
Hotels | Serviced Apartments and State of Art Serviced Hostels for Students, Working Class and
Co-living | Blue Collared Housing, across Pan India as the pioneer of adopting Blue Ocean
Strategy in the business of Real Estate. 
</p>

<p className="AboutExpoPara"> The Operations are run by the Founder and his
valuable partners who hail from high level business backgrounds supported by a team of
professionals having grass root level approach in Real Estate, having full fledged offices in
Dubai, Mumbai, Bengaluru, Chennai, Hyderabad, Goa & Vishakapatnam.
</p>

                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                             videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default FortuneAbout